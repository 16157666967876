<template>
  <li>
    <div type="button" class="d-flex justify-content-between align-items-center selected btn btn-outline-secondary" @click="nodeClick(item)" style="cursor: pointer">
      {{ item.name }}
    </div>
    <ul>
      <tree-item v-for="(child, index) in item.children" :key="index"
          ref="treeComponent"
          :item="child"
          :type="type"
          class="item"
          @node-click="nodeClick">
        </tree-item>
    </ul>
  </li>
</template>

<script>
export default {
  name: "TreeItem",
  props: {
    item: Object,
    type: String,
  },
  data() {
    return {
      item2:[],
    }
  },
  mounted() {
    console.log("child",this.type, this.child);
  },
  methods: {
    nodeClick: function (node) {
      this.$emit("node-click", node);
    }
  },
}
</script>
<style scoped>

</style>