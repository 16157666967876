<template>
  <div id="GalleryDialog" aria-hidden="true" class="modal" role="dialog" tabindex="-1"
       v-bind:class="{'show': libraryOpen}" v-bind:style="{display: (libraryOpen) ? 'block': 'none'}">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <form style="height: 100%;">
          <div class="modal-body d-flex">
            <div class="group-list">
              <!-- GROUP HIERARCHY VIEW -->
              <div class="type-select d-flex">
                <div class="form-check flex-grow-1 align-self-center">
                  <input id="radioPublic" ref="pubOpt" v-model="type" class="form-check-input" name="type" type="radio"
                         value="O">
                  <label class="form-check-label" for="radioPublic">Public</label>
                </div>
                <div class="form-check flex-grow-1 align-self-center">
                  <input id="radioPrivate" ref="priOpt" v-model="type" class="form-check-input" name="type" type="radio"
                         value="P">
                  <label class="form-check-label" for="radioPrivate">Private</label>
                </div>
              </div>
              <hr class="divider">
              <ul class="list-group">
                <!-- 그룹 TREE -->
                <tree-item v-for="(treeItem, index) in treeItems" v-bind:key="index"
                  :item="treeItem"
                  :type="type"
                  class="item list-group-item"
                  @node-click="nodeClick"
                ></tree-item>
              </ul>
            </div>
            <div class="vr"></div>
            <div class="item-list d-flex flex-column">
              <!-- DTMS 파일 목록 -->
              <ag-grid-vue :columnDefs="columnDefs"
                  :defaultColDef="defaultColDef"
                  :rowData="galleryItems"
                  class="ag-theme-alpine"
                  style="height: 500px"
                  @row-clicked="onRowClicked"
              ></ag-grid-vue>

              <dot-pagination
                v-if="totalCount > 0"
                :pageNo="pageNo"
                :pageSize="pageSize"
                :totalCount="totalCount"
                @pageClick="getGalleryItemsList">
              </dot-pagination>

              <div class="footer">
                <button class="btn btn-primary mt-3" type="button" @click.stop="$emit('close-library')">Close</button>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {$axios, $session} from "dot-framework";
import TreeItem from "./TreeItem";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {AgGridVue} from "ag-grid-vue3";
import dotPagination from '@/components/dot-ui/dot-pagination.vue';

class TreeData {
  constructor(_name = "Folder", _gno, _children = []) {
    this.name = _name;
    this.gno = _gno;
    this.children = _children;
  }
}

export default {
  name: 'GalleryViewDialog',
  components: {
    TreeItem,
    AgGridVue,
    dotPagination
  },
  props: {
    libraryOpen: Boolean
  },
  data() {
    return {
      treeItems: [], // 그룹의 트리
      galleryItems: [], // 갤러리 이미지 목록
      type: "P", // O(공용), P(개인)
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      columnDefs: [
        {headerName: 'Name', field: 'FILE_NAME', width: 200, sortable: true},
        {headerName: 'Description', field: 'FILE_DESC', width: 300},
        {headerName: 'Date', field: 'REG_DATE', width: 300, sortable: true},
      ],
      defaultColDef: {
          resizable: true,
      },
      selectedGroupNo: ""
    }
  },
  watch: {
    // radio 변경 이벤트
    type: function () {
      this.treeItems = [];
      this.getGroupList();
    }
  },
  mounted() {
    this.$refs.priOpt.click();
  },
  methods:
      {
        // 그룹 트리 노드 클릭
        nodeClick(item) {
          this.selectedGroupNo = item.gno;
          this.getGalleryItemsList(1);
        },
        // 갤러리 목록
        async getGalleryItemsList(pageNo) {
          this.pageNo = pageNo;
          const compNo = $session.getCompNo();
          let url = "/class-app/v1/comps/" + compNo + "/dtms";
          let param = "?PAGE_NO=" + this.pageNo + "&PAGE_SIZE=" + this.pageSize + "&DTMS_GROUP_NO=" + ((this.selectedGroupNo) ? this.selectedGroupNo : "ROOT") + "&DATA_TYPE=" + this.type;
          console.log("getGalleryItemsList: ", url+param);
          let response = await $axios.get(url + param);
          if (response.status == 200) {
            this.totalCount = response.data.TOTAL_COUNT;
            this.galleryItems = response.data.items;
          }
        },
        // 그룹 트리 데이타 생성
        makeTree(items) {
          // 트리 구조를 생성합니다. 현재 Depth=1 까지 생성 가능합니다.
          const _rootGroup = items.filter(function (el) {
            if (el.PARENT_GROUP_NO === "ROOT" || el.PARENT_GROUP_NO === "") {
              return el;
            }
          });

          for (let i = 0; i < _rootGroup.length; i++) {
            let treeData = new TreeData(_rootGroup[i].GROUP_NAME, _rootGroup[i].DTMS_GROUP_NO);
            let cnt = 0;
            for (let j = 0; j < items.length; j++) {
              if (items[j].PARENT_GROUP_NO === _rootGroup[i].DTMS_GROUP_NO) {
                let childData = new TreeData(items[j].GROUP_NAME, items[j].DTMS_GROUP_NO);
                treeData.children[cnt] = childData;
                cnt++;
                console.log(`GROUP_NAME: ${_rootGroup[i].GROUP_NAME}에 ${items[j].GROUP_NAME}이 추가됨.`);
              }
            }
            this.treeItems[i] = treeData;
          }
        },
        formatDate(date) {
          if (date != null || date != undefined) {
            return date.substr(0, 10);
          } else {
            return "-";
          }
        },
        // 그룹목록 조회
        async getGroupList() {
          this.treeItems = [];
          const compNo = $session.getCompNo();
          const userNo = $session.getUserNo();

          let url = "/class-app/v1/comps/" + compNo + "/dtmsgroup";
          let param = "?USER_NO=" + userNo + "&PAGE_NO=1&PAGE_SIZE=10&DTMS_GROUP_NO=&DATA_TYPE=" + this.type;
          console.log("getGroupList: ", url+param);
          let response = await $axios.get(url + param);
          if (response.status == 200) {
            if (this.type == "P") {
              // 개인용
              this.makeTree(response.data.items);
            } else if (this.type == "O") {
              // 공용
              this.makeTree(response.data.items);
            }
          }
        },
        // 갤러리 항목 클릭
        onRowClicked(event) {
          let item = event.node.data;
          this.$emit('get-dtms-detail', item.DTMS_FILE_NO, this.type);
          this.$emit('close-library');
        },
      },
}
</script>
<style scoped>
#GalleryDialog {
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.71);
}

.modal-content {
  width: 1000px; /*70vw;*/
  height: 640px; /*70vh;*/
}

.modal-body {
  height: 100%;
}

.group-list {
  overflow-x: hidden;
  overflow-y: auto;
  width: 20%;
  height: 100%;
  margin-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.group-list .type-select {
  padding: 0 10px;
}

.item-list {
  width: 80%;
  height: 100%;
  margin-left: 8px;
  padding-top: 35px;
}

.table {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table thead {
  background-color: rgba(147, 147, 147, 0.71);
}

.table tbody tr {
  height: 40px;
}

.table tbody tr:hover {
  font-weight: bold;
  background-color: #c0d2ff;
}

.table tbody tr:active {
  font-weight: bold;
  background-color: #a1bcff;
}

.table tbody tr:target-within {
  font-weight: bold;
  background-color: #a1bcff;
}

.table tbody tr td {
  overflow: hidden;
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentcolor;
}

.divider {
  margin: 0 0 0.5rem 0 !important;
}

#pageGroup {
  margin-bottom: 0;
  padding: 0;
}

#pageGroup li {
  display: inline;
  /*margin-left: 20px;*/
  margin: 0 10px;
  list-style-type: none;
}

#pageGroup .page {
  width: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

#pageGroup .page > a {
  text-decoration: none;
}

#pageGroup .page > a.selected {
  font-size: larger;
  font-weight: bold;
  color: #ff0330;
}

.pagination {
  justify-content: center;
  margin-top: 16px;
}

.pagination > button {
  border: none;
  background-color: transparent;
}

.pagination > button:disabled {
  background-color: transparent;
}

.footer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
}
</style>
